import { render, staticRenderFns } from "./MobileLabHeader.vue?vue&type=template&id=3fe8dd94&scoped=true&"
import script from "./MobileLabHeader.vue?vue&type=script&lang=js&"
export * from "./MobileLabHeader.vue?vue&type=script&lang=js&"
import style0 from "./MobileLabHeader.vue?vue&type=style&index=0&id=3fe8dd94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fe8dd94",
  null
  
)

export default component.exports