<template>
  <div class="lab-dropdown">
    <div class="user-dropdown" @click="isShowDropdownMenu = !isShowDropdownMenu" ref="dropdown" >
      <div class="lab-avatar">
        <b-img
          ref="previewEl"
          rounded
          :src="'https://image.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/avatar.svg'"
        />
      </div>
      <div class="user-dropdown-body">
        <div class="lab-name">{{userData.fullName}}</div>
        <div class="lab-id">CID: {{userData.userName}}</div>
      </div>
      <div class="lab-user-dropdown-icon">
        <div class="points-container">
          <div class="point"></div>
          <div class="point"></div>
          <div class="point"></div>
        </div>
      </div>
    </div>
    <div class="lab-dropdown-content" :class="{ 'show': isShowDropdownMenu}">
      <div class="user-dropdown-menu">
        <div class="user-dropdown-menu-item" @click="$router.push({ name: 'profile-settings' })">
          <span class="icon">
            <img :src="'https://image.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/profile_settings.svg'">
          </span>
          <span class="text">
            Settings
          </span>
        </div>
        <div class="user-dropdown-menu-item" @click="logout()">
          <span class="icon">
            <img :src="'https://image.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/logout.svg'">
          </span>
          <span class="text">
            Log out
          </span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isShowDropdownMenu: false,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdown;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownMenu = false;
        }
      } catch (error) {}
    },
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  }
}
</script>

<style lang="scss" scoped>
.user-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.user-dropdown .lab-user-dropdown-icon {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-dropdown .lab-avatar {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  border: 2px solid var(--lab-coronation);
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-dropdown .lab-avatar img {
  height: 27px;
  width: 27px;
}
.user-dropdown .user-dropdown-body {
  margin-right: 0;
  margin-left: 10px;
}
.user-dropdown .user-dropdown-body .lab-name {
  color: var(--lab-black);
  font-size: 0.8rem;
  font-weight: bold;
}

.points-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.point {
  width: 4px;
  height: 4px;
  background-color: var(--lab-black);;
  border-radius: 50%; /* Make it round */
  margin: 2.5px 0; /* Adjust spacing between points */
}

.user-dropdown .user-dropdown-body .lab-id {
  color: var( --lab-dust-to-dust);
  font-size: 0.7rem;
  font-weight: bold;
}

.user-dropdown-menu .user-dropdown-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 5px;
  border-radius: 5px;
  transition: var(--lab-trans-03);
}

.user-dropdown-menu .user-dropdown-menu-item:hover {
  background-color: var(--bg-card-hover-color);
}

.user-dropdown-menu img {
  width: 18px;
  height: 18px;
}

.user-dropdown-menu .text {
  margin-left: 10px;
  color: var(--lab-text-color-title-03);
  font-weight: bold;
  font-size: 0.8rem;
}

.dark-layout {
  .user-dropdown .user-dropdown-body .lab-id {
    color: var(--lab-cascading-white);
  }
  .user-dropdown .user-dropdown-body .lab-name {
    color: var(--lab-lago-blue-color);
  }
  .point{
    background-color: var(--lab-lago-blue-color);
  }
}

@media screen and (max-width: 769px) {
  .user-dropdown .user-dropdown-body .lab-name {
    font-size: 0.8rem;
  }

  .user-dropdown .user-dropdown-body .lab-id {
    font-size: 0.8rem;
  }

  .user-dropdown .lab-avatar img {
    height: 30px;
    width: 30px;
  }

  .user-dropdown .lab-user-dropdown-icon {
    display: none;
  }

  .user-dropdown .lab-dropdown-content {
    display: none;
  }
}

</style>
