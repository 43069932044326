<template>
    <nav class="sidebar" :class="{ 'hidden-menu-expand': isHiddenMenuExpand}">
      <header>
        <div class="icon-text" @click="$router.push({name: 'dashboard'})">
          <img  :src="iconUrl">
        </div>
      </header>
      <hr class="icon-hr  mb-3">
      <div class="lab-menu-bar">
        <div class="lab-menu">
          <ul class="lab-menu-links">
            <li class="nav-link dashboard"  @mouseover="hoverMenuCode = 'DASHBOARD'" @mouseout="hoverMenuCode = ''">
              <router-link to="/dashboard" :class="{ 'active': $route.meta.code == 'DASHBOARD'}">
                <span class="icon">
                  <img :src="$route.meta.code === 'DASHBOARD' || hoverMenuCode === 'DASHBOARD' ? menuIconUrl+theme+'/active/home.svg' : menuIconUrl+theme+'/active/home.svg'">
                </span>

                <span class="text">Dashboard</span>
              </router-link>
            </li>
            <hr class="lab-mt-20 mb-2">
            <li class="nav-link g25" @mouseover="hoverMenuCode = 'G25'" @mouseout="hoverMenuCode = ''">
              <router-link to="/dashboard/da" :class="{ 'active': $route.meta.code == 'G25-DASHBOARD' || $route.meta.code == 'G25'}">
                <span class="icon">
                  <img :src="$route.meta.code == 'G25-DASHBOARD' || $route.meta.code === 'G25' || hoverMenuCode === 'G25' || completedG25Order ? menuIconUrl+theme+'/active/deep-ancestry.svg' : menuIconUrl+theme+'/normal/deep-ancestry.svg'">
                </span>

                <span class="text">DEEP ANCESTRY</span>
              </router-link>
            </li>
            <!-- <li class="nav-link traits" @mouseover="hoverMenuCode = 'TRAIT-HEALTH'" @mouseout="hoverMenuCode = ''">
              <router-link to="/dashboard/traits" :class="{ 'active': $route.meta.code == 'TRAIT-HEALTH'}">
                <span class="icon">
                  <img :src="$route.meta.code === 'TRAIT-HEALTH' || hoverMenuCode === 'TRAIT-HEALTH' || completedTraitOrder ? menuIconUrl+theme+'/active/trait-health.svg' : menuIconUrl+theme+'/normal/trait-health.svg'">
                </span>

                <span class="text">TRAITS & HEALTH</span>
              </router-link>
            </li>
            <li class="nav-link ibd" @mouseover="hoverMenuCode = 'IBD'" @mouseout="hoverMenuCode = ''">
              <router-link to="/dashboard/ibd" :class="{ 'active': $route.meta.code == 'IBD'}">
                <span class="icon">
                  <img :src="$route.meta.code === 'IBD' || hoverMenuCode === 'IBD' ? menuIconUrl+theme+'/active/ibd.svg' : menuIconUrl+theme+'/normal/ibd.svg'">
                </span>

                <span class="text">POP-WISE & IBD</span>
              </router-link>
            </li> -->
          </ul>
        </div>
      </div>

      <div class="lab-nav-bottom">
        <li>
          <a class="nav-toggle" @click="onToggleMenu();">
            <span class="icon">
              <img :src="iconToggleUrl">
            </span>
          </a>
        </li>
      </div>
    </nav>
</template>

<script>
export default {
  components: { 
  },
  props: {
  },
  data() {
    return {
      menuIconUrl: 'https://image.illustrativedna.com/assets/icons/menu/',
      isHiddenMenuExpand: true,
      hoverMenuCode: '',
    }
  },  
  computed: {
    theme() {
      return this.$store.state.appConfig.layout.skin;
    },
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
    iconUrl() {
      /* eslint-disable */
      return !this.isHiddenMenuExpand ? 'https://image.illustrativedna.com/assets/icons/' + this.theme + '/icon-text.svg' : 'https://image.illustrativedna.com/assets/icons/icon.svg';
    },
    iconToggleUrl() {
      /* eslint-disable */
      return this.isHiddenMenuExpand ? 'https://image.illustrativedna.com/assets/icons/'+this.theme+'/menu-toggle-right.svg' : 'https://image.illustrativedna.com/assets/icons/'+this.theme+'/menu-toggle-left.svg';
    },
    completedG25Order() {
      return this.$store.getters['orders/getOrders'].find(x => (x.productType === 1 || x.productType == 2) && x.orderStatus === 3);
    },
    completedTraitOrder() {
      return this.$store.getters['orders/getOrders'].find(x => (x.productType === 3) && x.orderStatus === 3);
    },
  },
  methods: {
    onToggleMenu() {
      this.isHiddenMenuExpand = !this.isHiddenMenuExpand;
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-hr {
  width: 114% !important;
  margin-left: -14px !important;
}

.sidebar.hidden-menu-expand .icon-hr {
  width: 144% !important;
  margin-left: -14px !important;
}

.sidebar header {
  position: relative;
}

.sidebar header .icon-text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.sidebar header .icon-text  img {
  height: 45px;
  display: flex;
  align-items: center;
}

.sidebar {
  position: fixed;
  height: 100%;
  width: 250px;
  padding: 10px 25px;
  background: var(--sidebar-bg-color);
  transition: var(--lab-trans-05);
  z-index: 500;
}

.sidebar.hidden-menu-expand {
  width: 110px;
}

.sidebar.hidden-menu-expand .text{
  opacity: 0;
  visibility: hidden;
}

.sidebar .nav-link {
  padding: 0;
}

.sidebar .lab-nav-bottom {
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  width: 222px;
}

.sidebar .lab-nav-bottom li {
  margin-left: auto;
}

.sidebar .lab-nav-bottom li .icon {
  transition: var(--lab-trans-02) !important;
  img {
    height: 40px !important;
    width: 40px !important;
  }
}

.sidebar.hidden-menu-expand .lab-nav-bottom li {
  margin-left: 0;
}

.sidebar ul {
  padding: 0;
}
.sidebar li {
  height: 60px;
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
  transition: var(--lab-trans-02);
}
.sidebar li .icon {  
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  font-size: 20px;
  transition: var(--lab-trans-02);
}

.sidebar li .icon img {
  width: 30px !important;
  height: 30px !important;
}

.sidebar li .text {
  color: var(--sidebar-title-color);
  padding-left: 15px;
  font-weight: 700;
  font-size: 1rem;
  transition: var(--lab-trans-03);
  opacity: 1;
}

.sidebar li  a.active .text {
  color: var(--sidebar-title-active-color) !important;
}
.sidebar li  a:hover .text {
  color: var(--sidebar-title-active-color) !important;
}

.dark-layout .sidebar li  a.active .text {
  color: var(--lab-minted-elegance-color) !important;
}
.dark-layout .sidebar li  a:hover .text {
  color: var(--lab-minted-elegance-color) !important;
}

.dark-layout .sidebar li.g25  a.active .text {
  color: var(--lab-lago-blue-color) !important;
}
.dark-layout  .sidebar li.g25  a:hover .text {
  color: var(--lab-lago-blue-color) !important;
}

.dark-layout .sidebar li.traits  a.active .text {
  color: var(--lab-fight-sunrise-color) !important;
}
.dark-layout .sidebar li.traits  a:hover .text {
  color: var(--lab-fight-sunrise-color) !important;
}

.dark-layout .sidebar li.ibd  a.active .text {
  color: var(--lab-pale-marigold-color) !important;
}
.dark-layout .sidebar li.ibd  a:hover .text {
  color: var(--lab-pale-marigold-color) !important;
}

.sidebar li a {
  height: 100%;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-radius: 6px;
  transition: var(--lab-trans-03);
}

.menu a:hover {
  color: transparent;
}

.sidebar li.dashboard a:hover {
  transform: scale(1.1);
  background-color: #6fceaa21;
}

.sidebar li.dashboard a.active {
  background-color: #6fceaa21;
}

.sidebar li.g25 a:hover {
  transform: scale(1.1);
  background-color: #1bbfcc1f;
}

.sidebar li.g25 a.active {
  background-color: #1bbfcc1f;
}

.sidebar li.traits a:hover {
  transform: scale(1.1);
  background-color: #ef5e8b24;
}

.sidebar li.traits a.active {
  transform: scale(1.1);
  background-color: #ef5e8b24;
}

.sidebar li.ibd a:hover {
  transform: scale(1.1);
  background-color: #ffbc471c;
}

.sidebar li.ibd a.active {
  background-color: #ffbc471c;
}

</style>
