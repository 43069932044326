<template>
  <div>
    <div class="mobile-bottom-nabvar">
      <b-link class="" :to="{name:'dashboard'}">
        <b-img :src="'https://image.illustrativedna.com/assets/icons/mobile/'+$store.state.appConfig.layout.skin+'_home.svg'" width="18" height="18"/>
        <div class="text">
          <span>Dashboard</span>
        </div>
      </b-link>
      <b-link @click="openModalKit()">
        <b-img :src="'https://image.illustrativedna.com/assets/icons/mobile/'+$store.state.appConfig.layout.skin+'_switch_kits.svg'" width="18" height="18"/>
        <div class="text">
          <span>Switch Kits</span>
        </div>
      </b-link>
      <a ref="mobile_profile">
        <b-img :src="'https://image.illustrativedna.com/assets/icons/mobile/'+$store.state.appConfig.layout.skin+'_profile.svg'" width="18" height="18"/>
        <div class="text">
          <span>Profile</span>
        </div>
      </a>
    </div>

    <mobile-lab-order-kits v-if="modalKitObject.showModal" :modalObject="modalKitObject"/>
  </div>
</template>

<script>
import MobileLabOrderKits from './order-kits/MobileLabOrderKits.vue'

export default {
  components: {
    MobileLabOrderKits,
  },
  props: {
  },
  data() {
    return {
      modalKitObject: {
        showModal: false,
      },
    }
  },
  methods: {
    openModalKit() {
      this.modalKitObject.showModal = true;
    },
  },
  computed: {
  },
}
</script>

<style lang="scss">
.mobile-bottom-nabvar {
  overflow: hidden;
  background-color: var(--lab-light-house);
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;
}

.mobile-bottom-nabvar a {
  float: left;
  display: block;
  color: var(--lab-black);
  text-align: center;
  padding: 7px 13px;
  text-decoration: none;
}

.mobile-bottom-nabvar .text {
  margin-top: 3px;
  font-size: 0.75rem;
  font-weight: bold;
}

.mobile-bottom-nabvar a .text {
  color: var(--lab-black);
}

.dark-layout .mobile-bottom-nabvar {
  background-color: var(--lab-washed-black);
}
.dark-layout .mobile-bottom-nabvar a .text {
  color: var(--lab-lago-blue-color);
}
</style>
