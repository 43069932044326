<template>
  <div class="lab-dropdown mr-1" v-if="$store.getters['orderResult/getOrderResults'].length">
    <div class="lab-dropdown-btn" @click="isShowDropdownMenu = !isShowDropdownMenu" ref="dropdown">
      <span class="lab-dropdown-btn-value">{{ orderResults[selectedOrderResultIndex].resultTitle}}</span>
      <span class="lab-dropdown-btn-icon">
        <img :src="'https://image.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/dropdown.svg'">
      </span>
    </div>
    <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownMenu}">
      <ul>
        <li v-for="(resultItem, index)  in $store.getters['orderResult/getOrderResults']"
            :key="resultItem.id"
            @click="onChangeResultItem(resultItem, index)">
          {{ resultItem.resultTitle }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isShowDropdownMenu: false,
      selectedOrderResultIndex: 0,
    }
  },
  methods: {
    onChangeResultItem(item, index) {
      this.selectedOrderResultIndex = index;
      this.$store.commit('orderResult/setTargetData', item);
      
      if (this.$router.currentRoute.name !== 'order-result-da') {
        this.$router.push({ name: 'dashboard-da' });
      }
    },
    fetchRegions(callback) {
      if (this.$store.getters['operation/getRegions'].length) {
        if (typeof callback === 'function') {
          callback();
        }
      } else {
        this.$store.dispatch('operation/fetchRegionGrups', {}).then(() => {
          this.$store.dispatch('operation/fetchRegions', {})
            .then(() => {
              if (typeof callback === 'function') {
                callback();
              }
            })
        });
      }
    },
    fetchOrderResults() {
      this.fetchRegions(() => {
        this.$store.dispatch('orderResult/fetchOrderResults').then(response => {
          if (response.statusCode === 200) {
            this.$store.commit('orderResult/setOrderResults', response.result);

            if (response.result.length > 0) {
              this.$store.commit('orderResult/setTargetData', response.result[0]);
            }
            
            const queryPcaConnectRegionId = response.result.filter(x => x.presetRegionId !== null).map(x => x.presetRegionId);
            if (queryPcaConnectRegionId) {
              ApiService.postSilent('PresetRegion/pca-connected', { presetRegionList: queryPcaConnectRegionId }, this)
                .then(responseConnected => {
                  if (responseConnected) {
                    const pcaregions = this.$store.getters['operation/getPcaRegions'];
                    response.result.forEach(value => {
                      const findPcaRegionConnect = responseConnected.result.find(x => x.regionId === value.presetRegionId);
                      if (findPcaRegionConnect) {
                        const findPcaRegion = pcaregions.find(x => x.id === findPcaRegionConnect.pcaRegionId);
                        if (findPcaRegion) {
                          value.pcaRegionId = findPcaRegion.id;
                          value.pcaRegionGrupId = findPcaRegion.presetRegionGrupId;
                        }
                      } else {
                        value.pcaRegionId = null;
                        value.pcaRegionGrupId = null;
                      }
                    });
                    this.$store.commit('orderResult/setOrderResults', response.result);
                  }
                });
            }
          } else {
            AlertService.error(this, response.message)
          }
        });
        this.isLoad = true;
      });
    },

    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdown;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownMenu = false;
        }
      } catch (error) {}
    },
  },
  computed: {
    orderResults() {
      return this.$store.getters['orderResult/getOrderResults'];
    },
  },
  created() {
    this.fetchOrderResults();
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  }
}
</script>

<style lang="scss" scoped>
.dark-layout .lab-dropdown .lab-dropdown-btn {
  color: var(--lab-lago-blue-color);
  border: 2px solid var(--lab-lago-blue-color);
}
</style>
