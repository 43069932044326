<template>
  <div class="lab-header" :class="{ 'scroll-header': isScrolled }">
    <div class="lab-header-left">
      <div v-if="$route.meta.code === 'G25'">
        <button class="lab-btn lab-btn-back" @click="$router.push({ name: 'dashboard-da'})" > 
          <feather-icon icon="ArrowLeftIcon" size="20"/>
          Back
        </button>
      </div>

      <!-- <div v-if="$route.meta.code === 'DASHBOARD'" class="header-title">
        Welcome, [USER NAME]
      </div>
      <div v-else class="header-title">
        {{ $route.meta.pageTitle }} {{dynamicTitle}}
      </div> -->
    </div>
    <div class="lab-header-menu">
      <ul>
        <li class="menu-item kit mr-2">
          <lab-order-kits />
          <lab-order-kits-trait v-if="pageCode === 'TRAIT-HEALTH'"/>
        </li>
        <li class="menu-item">
          <lab-notification-dropdown />
        </li>
        <li class="menu-item">
          <lab-dark-toggler />
        </li>
        <li class="menu-item ml-2">
          <lab-user-dropdown />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { DA_RESULT_TABS } from '@/common/config'
import LabDarkToggler from './nav-bar-components/LabDarkToggler.vue'
import LabOrderKits from './nav-bar-components/order-kits/LabOrderKits.vue'
import LabOrderKitsTrait from './nav-bar-components/order-kits/LabOrderKitsTrait.vue'
import LabNotificationDropdown from './nav-bar-components/LabNotificationDropdown.vue'
import LabUserDropdown from './nav-bar-components/LabUserDropdown.vue'

export default {
  components: { 
    LabDarkToggler,
    LabOrderKits,
    LabOrderKitsTrait,
    LabNotificationDropdown,
    LabUserDropdown,
  },
  props: {
  },
  data() {
    return {
      isScrolled: false,
    }
  },  
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
    dynamicTitle() {
      /* eslint-disable */
      if (this.$route.meta.pageTitle === 'G25 >') {
        this.selectedResultType = this.$router.currentRoute.params.type;
        return DA_RESULT_TABS[this.selectedResultType].title;
      }
      else {
        return '';
      }
    },
    pageCode() {
      return this.$route.meta.code;
    }
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style lang="scss" scoped>

.lab-header {
  position: fixed;
  width: calc(100% - 252px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 86px;
  transition: var(--lab-trans-03);
  z-index: 1001;
  padding-right:20px ;
  border-bottom: 0 solid transparent;
}

.lab-header.scroll-header {
  background-color: var(--bg-card-color);
  border-bottom: var(--lab-card-border);
  box-shadow: var(--lab-card-shadow);
}

.lab-header .header-title {
  color: var(--lab-text-color-title-01);
  font-size: 1.4rem;
  font-weight: 600;
}

.lab-header-menu {
  height: 100%;
  display: flex;
  align-items: center;
}

.lab-header-menu ul {
  margin-top: 10px;
  margin-right: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.lab-header-menu li {
  float: left;
  margin-left: 10px;
}

.menu-item.kit {
  margin-left: 0px !important;
  position: relative;
}

.menu-item.kit::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%; 
  width: 2px;
  background-color: var(--lab-hr-color);
}

.lab-header-left {
  padding: 2rem;
}

.lab-btn-back {
  padding: 0.4rem 1rem 0.5rem 0.8rem;
  background-color: var(--lab-carbon-fiber);
  color: var(--lab-white);
  font-size: 0.9rem;
  font-weight: bold;
}

.dark-layout {
  .lab-btn-back {
    background-color: var(--lab-turquoise-sea);
    color: var(--lab-white);
  }

  .lab-header.scroll-header {
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.17);
  }
}

</style>

<style lang="scss">
.dark-layout {
  .lab-header.scroll-header {
    .notification-dropdown svg .cls-1{
      fill: #13181c;
    }
  }
}
</style>
